<template>
    <div class="p-3">
        <Menu :model="items" />

        <div class="bottom-0 absolute pb-3 text-left">


            <PrimeButton icon="pi pi-sign-out" label="Выйти"  class=" p-button-raised p-button-text bg-white" @click="logoff" />
            <div class="mt-5"><a href="https://t.me/gramotacafe" target="_blank" class="text-white">Написать в поддержку</a></div>
        </div>
    </div>

</template>

<script>
import Menu from 'primevue/menu';
import {user} from '@/libs/auth'
export default {
    components: {Menu},
    data(){
        return {
            items: [
                {
                label: 'Проекты',
                icon: 'pi pi-file',
                to: '/projects'
            }
            ]

        }
    },
    methods:{
        logoff(){
            user.logoff();
        }
    }
}
</script>

<style scoped>

</style>
