import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'


import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FileUpload from 'primevue/fileupload';
import PrimeSlider from 'primevue/slider';
import Panel from 'primevue/panel';
import SelectButton from 'primevue/selectbutton';
import PrimeDropdown from 'primevue/dropdown';
import ColorPicker  from 'primevue/colorpicker';
import PrimeToolbar  from 'primevue/toolbar';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import Textarea from 'primevue/textarea';

import '@/assets/styles.scss';

const app = createApp(App);

app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);

app.component('PrimeDialog', Dialog);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('PrimeButton', Button);
app.component('PrimePanel', Panel);
app.component('DataTable', DataTable);
app.component('TableColumn', Column);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('FileUpload', FileUpload);
app.component('PrimeSlider', PrimeSlider);
app.component('SelectButton', SelectButton);
app.component('PrimeDropdown', PrimeDropdown);
app.component('ColorPicker', ColorPicker);
app.component('PrimeToolbar', PrimeToolbar);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ContextMenu ', ContextMenu);
app.component('PrimeTree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('InputSwitch', InputSwitch);
app.component('PrimeTextArea', Textarea);


app.mount('#app')
