<template>
    <div class="bg-selector">
        <div class="overlay"></div>
        <div class="window text-left">
            <i class="pi pi-times cursor-pointer m-auto close-btn" @click="closeModal"></i>
            <h1 class="text-left">Шаблоны</h1>
            <div class="flex flex-gap-2 flex-wrap template-list">
                <div v-for="item in bgTemplates" :key="item.id" class="template-list-item" @click="select(item)">
                    <div class="preview">
                        <img :src="'/backgrounds/p_'+item.filename+'?1'" alt="">
                    </div>
                    <div class="template-name">{{item.name}}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import {ref, defineEmits} from "vue";
    import api from "@/libs/api";

    const bgTemplates = ref([]);

    const emit = defineEmits(['close']);

    const closeModal = () => emit('close');

    const select = (item) => emit('select', item);

    const load = () => {
        console.log('load templates');
        api.getBgTemplates().then(data => bgTemplates.value = data);
    }
    load();

</script>

<style scoped lang="scss">
.bg-selector{
    .overlay{
        position: fixed;
        top: 0;
        left: 0;
        background-color: #00000099;
        width: 100%;
        height: 100vh;
        z-index: 1200;
    }
    .window{
        position: fixed;
        top: calc(50% - 40vh);
        left: calc(50% - 40vw);
        width: 80vw;
        background-color: white;
        padding: 25px;
        height: 85vh;
        z-index: 1201;
    }

    .template-list{
        max-height: calc(80vh - 100px);
        overflow-y: auto;
        .template-list-item{
            padding: 20px 10px;
            cursor: pointer;
            &:hover{
                .preview {
                    box-shadow: 0 1px 2px 0px #aaaaaa;
                }
                .template-name{
                    color: var(--blue-600);
                }
            }

            .template-name{
                text-align: center;
                padding-top: 5px;
            }
            .preview {
                width: 130px;
                height: 130px;
                padding: 10px;
                background: #eeeeee;
                display: flex;

                img{
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }

            }
        }
    }
    .close-btn{
        position: absolute;
        top: 40px;
        right: 40px;
        font-size: 1.5rem;
    }

}
</style>
