import axios from "axios";
import events from "@/libs/events";
import EventBus from "@/libs/event-bus";

axios.defaults.withCredentials = true;

const prodServer = 'http://gramotacafe.ru'
const devServer= 'http://gramota-press.loc'

axios.defaults.baseURL=process.env.NODE_ENV == "development" ? devServer : prodServer;

const user = {
    isAuthorized(){
        return localStorage.getItem('user_id') > 0;
    },
    logoff(){
        localStorage.removeItem('user_id');
        axios.post("/logout");
        EventBus.$emit(events.LOGOFF);
    },
    login(username, password){
        return new Promise((resolve) => {

            axios.get('/sanctum/csrf-cookie').then(() => {
                // Login...

                axios.post("/login", {
                    email: username,
                    password
                }).then(response => {

                    localStorage.setItem('user_id',response.data.user_id);
                    resolve(response)
                })
                    .catch(err => console.error(err))
            });

        })

    },

    setCSRF(){

    }
};
EventBus.$on(events.STATUS_401_UNAUTHORIZED, () =>{
    localStorage.removeItem('user_id');
});
export {
    user
}
