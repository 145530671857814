<template>
    <div   class="table-wrapper">
        <div class="link" style="margin: 10px 0; padding: 10px">
            Ссылка для генерации документов по API: <span style="background: #a8d8ff; display: inline-block; padding: 0 10px">https:\\gramotacafe.ru\user_api\v1\createDoc\{{project.id}}?token={{apiKey}}</span>        </div>

        <DataTable
                   :value="projectData.rows"

                   responsiveLayout="scroll"
                   showGridlines
                   :scrollable="true" :scrollHeight="tableHeight" :loading="loading" scrollDirection="both"
                   style="max-width: 100%"

        >
            <TableColumn
                         style="max-width:60px"
                         field="id"
                         class="text-center"
                         headerStyle="text-align: center;"
                         header="PDF"
                         :key="'pdf'" >
                <template #body="{data}">
                    <i class="pi pi-file-pdf cursor-pointer m-auto" @click="downloadOne(data)"></i>
                </template>

            </TableColumn>
            <TableColumn v-for="col of columns"
                         style="width:200px"
                         :field="col.column"
                         :header="col.name" :key="col.column" ></TableColumn>
        </DataTable>
        <div class="flex justify-content-between mt-5 w-full" >
            <div>
                <FileUpload mode="basic" :custom-upload="true" choose-label="Загрузить файл данных" auto @uploader="upload" name="datafile" :file-limit="1" />
            </div>
            <div>
                Имя файла брать из колонки: <PrimeDropdown v-model="filenameKey" class="p-dropdown-sm mr-1" :options="dataKeys" />
                <PrimeButton @click="downloadAll"><i class="pi pi-download mr-3"></i>Скачать все</PrimeButton>
            </div>
            <div class="text-right">
                Строк: {{rowCount}}
            </div>

        </div>
    </div>
</template>

<script>
import api from "@/libs/api";

export default {
    props: ['project'],
    data(){
        return {
            loading: false,
            uploadUrl: '',
            project_id: null,
            filenameKey: 'row',
            apiKey: null,
            projectData: {
                keys:[],
                rows:[]
            },
            tableHeight: '400px'
        }
    },
    computed:{
        rowCount(){ return this.projectData.rows.length},
        columns(){return this.projectData.keys},
        dataKeys(){return this.projectData.keys.map(item => item.name)}

    },
    methods:{
        downloadOne(data){
            api.downloadFileForNumber(this.project_id, data.row)
        },
        downloadAll(){
            api.downloadFiles(this.project_id, this.filenameKey)
        },
        upload(data){
            api.uploadDataFile(this.project_id, data.files).then( () => this.reload());
        },
        load(){
            this.loading = true;
            api.viewProjectData(this.project_id).then(data => {
                this.projectData.keys = data.keys;
                this.projectData.rows = data.values;
                this.apiKey = data.apiKey;
                this.loading = false;
            });
        },
        reload(){
            return this.load();
        }
    },


    mounted() {
        this.project_id = this.$route.params.project_id;
        this.tableHeight = window.innerHeight - 300 + 'px';
        this.load();

    }
}
</script>

<style scoped>

</style>
