import axios from "axios";
import userApi from "@/libs/api/user";
import projectsApi from "@/libs/api/projects"

import EventBus from "@/libs/event-bus";
import events from "@/libs/events";



const apiServer = process.env.VUE_APP_SERVER;
const  apiPrefix = "/api/v1.0/";


axios.defaults.withCredentials = true;
axios.defaults.baseURL=apiServer;



const api = {

    server: apiServer,

    send:{
        urlWithPrefix(endpoint){
            return apiPrefix + endpoint;
        },
        fullPath(endpoint){
            return apiServer + this.urlWithPrefix(endpoint);
        },

        __promise(type, endpoint, payload, config){
            let axPromise = null;
            let configObj = config ?? {};
            switch (type){
                case "get":
                    axPromise = axios.get(this.urlWithPrefix(endpoint), configObj);
                    break;

                case "post":
                    axPromise =  axios.post(this.urlWithPrefix(endpoint), payload, configObj);
                    break;

                case "put":
                    axPromise =  axios.put(this.urlWithPrefix(endpoint), payload, configObj);
                    break;

                case "delete":
                    axPromise =  axios.delete(this.urlWithPrefix(endpoint), configObj);
                    break;

                default:
                    throw new Error("Incorrect request type: "+ type);
            }
            return new Promise((resolve, reject) => {
                axPromise.then(response => resolve(response.data))
                    .catch(err => {
                        if(err.request.status === 401){
                            EventBus.$emit(events.STATUS_401_UNAUTHORIZED);
                            return;
                        }
                        reject(err);
                    })
            });

        },
        get(url, config){
            return this.__promise('get', url, config)
        },
        post(url, payload, config){
            return this.__promise('post', url, payload, config)
        },
        put(url, payload, config){
            return this.__promise('put', url, payload, config)
        },
        delete(url, config){
            return this.__promise('delete', url, config)
        },
    },

    getUser(){ return userApi.get(this) },
    getProjects() { return projectsApi.list(this)},
    getFoldersAndProjects() { return projectsApi.listWithFolders(this)},
    viewProject(project_id) { return projectsApi.view(this,project_id)},
    updateProjectName(project_id, name) { return projectsApi.updateProjectName(this,project_id, name)},
    updateProjectPublicAccess(project_id, allowAccess, passwordField, accessComment) { return projectsApi.updateProjectPublicAccess(this,project_id, allowAccess, passwordField, accessComment)},

    createFolder(folder_name, parent_folder_id) {return projectsApi.createFolder(this,folder_name,parent_folder_id)},
    deleteFolder(folder_id) { return projectsApi.deleteFolder(this, folder_id) },
    renameFolder(folder_id, folder_name) { return projectsApi.updateFolderName(this, folder_id, folder_name)},

    copyProject(project_id) { return projectsApi.copyProject(this,project_id)},
    replaceProject(project_id, folder_id) { return projectsApi.replaceProject(this,project_id, folder_id)},
    createProject(project_name, folder_id) { return projectsApi.createProject(this,project_name, folder_id)},
    deleteProject(project_id) { return projectsApi.deleteProject(this,project_id)},

    viewProjectData(project_id) { return projectsApi.getData(this,project_id)},

    uploadDataFile(project_id, files) { return projectsApi.uploadData(this,project_id, files)},
    downloadFileForNumber(project_id, row_number) {return projectsApi.downloadFileForRowNumber(this, project_id, row_number)},
    downloadFiles(project_id, filenameKey) {return projectsApi.downloadAllFiles(this, project_id, filenameKey)},

    viewProjectTemplate(project_id) { return projectsApi.getTemplate(this,project_id)},
    uploadProjectTemplateImage(project_id, files) { return projectsApi.uploadTemplateImage(this,project_id, files)},
    copyTemplateImageFromLibrary(project_id, backgroundId) {return projectsApi.copyTemplateImageFromLibrary(this,project_id, backgroundId)},
    createProjectTemplate(project_id, data) { return projectsApi.createProjectTemplate(this,project_id, data)},
    updateProjectTemplate(project_id, data) { return projectsApi.updateProjectTemplate(this,project_id, data)},

    getAvailableFonts()  { return projectsApi.availableFonts(this)},

    getBgTemplates() { return projectsApi.listBgTemplates(this)},

    loadAccessSheet(project_id) {return projectsApi.loadAccessSheet(this, project_id)}


};

export default api;
