<template>


    <div class="wrapper">
        <div class="layout relative" :style="layoutStyle" @click="disableActiveBlock"  @drop="onDragDrop" @dragover="onDragOver">
            <canvas style="position:absolute; top:0; left:0;" width="796" height="1126" id="can1"></canvas>
            <template v-for="(block,idx) in template.blocks">
                <template v-if="block.type === 'text'">
                    <TextBlockWidget v-model="template.blocks[idx]" :activeBlockId="activeBlock"
                        :zoom="zoom" :number="idx+1" :key="block.id"
                        :editMode="props.editMode"
                         @click="activateBlock($event,block.id)"
                        @moveBlock="onMoveBlock"

                        @editBlockEnabled="emit('onTextWidgetDblClick')"
                        />
            </template>
            <template v-if="block.type === 'img'">
                <ImgBlockWidget v-model="template.blocks[idx]" :activeBlockId="activeBlock"
                        :zoom="zoom" :number="idx+1" :key="block.id"
                        :basePath="projectTemplateImagePath"
                         @click="activateBlock($event,block.id)"
                        @moveBlock="onMoveBlock"
                        />
            </template>

            </template>


        </div>
    </div>
</template>

<script setup>
import TextBlockWidget  from "@/components/TextBlockWidget";
import ImgBlockWidget  from "@/components/ImageBlockWidget";
import api from "@/libs/api";
import {defineComponent, defineProps, defineEmits, ref, computed} from "vue";

defineComponent(TextBlockWidget);

const emit = defineEmits(['update:modelValue','moveBlock','blockClick', 'onTextWidgetDblClick'])



const props = defineProps(['modelValue','project','activeBlock','editMode']);
const zoom = ref(1);

const pixelsInSm = 3.79;
const mmToPixel = (mm, zoom) => Math.round(mm * pixelsInSm * zoom);
// const pixelToMM = (pixels) => Math.round(props.zoom * pixels / pixelsInSm);

//pass event to parent
const onMoveBlock = (e) => emit('moveBlock',e);

const projectTemplateImagePath = computed(() =>{
    return props.project !== undefined ? api.server + '/projects/' + props.project.guid+ '/images/'  : '';
})

const template = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const layoutStyle = computed(() => {
    return {
        position: 'relative',
        margin: 'auto',
        width: mmToPixel(template.value.width, 1) + 'px',
        height: mmToPixel(template.value.height, 1) + 'px',
        background: template.value.background_file !== undefined ? 'url("'+projectTemplateImagePath.value+template.value.background_file+ '") no-repeat #fff' : '#fff',
        'background-size' : 'cover'
    }

});

const activateBlock = (e,blockId) => {
    e.preventDefault();
    e.stopPropagation();

    //if already active
    if(props.activeBlock === blockId) return;


    emit('blockClick', blockId)
}

const disableActiveBlock = () => emit('blockClick', -1)
const onDragDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
}
const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "move";
}
</script>

<style scoped lang="scss">
.wrapper{
    background-color: #4a5568;
    padding: 10px;
}
.layout{
    background-color: #fff;
}

</style>
