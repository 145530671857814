<template>
    <div
         :style="blockStyle"
         @click="onClick"
         @dragstart="onDragStart"
         @dragend="onDragEnd"
         @drag="onDrag"
         draggable="true"
         class="hoverBorder"
         :contenteditable="contentEditable && isActive"
         @dblclick="editModeOn"
         @focusout="editModeOff"
         @input="onInput"
    >{{ block.text }}</div>
</template>
<script setup>

import {ref, computed, defineProps, defineEmits} from "vue"

const props = defineProps(['modelValue', 'activeBlockId', 'zoom', 'number'])

const emit = defineEmits(['update:modelValue', 'activateBlock', 'moveBlock','editBlockEnabled']);
const block = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const contentEditable = ref(false);
const editModeOn = () => {
    contentEditable.value = true;
    emit('editBlockEnabled');
}
const editModeOff = () => {
    contentEditable.value = false;
}
const pixelsInSm = 3.79;
const mmToPixel = (mm) => Math.round(mm * pixelsInSm * props.zoom);
const pixelToMM = (pixels) => Math.round(props.zoom * pixels / pixelsInSm);


const onInput = (e) => {
    block.value.text = e.target.innerText
    emit('update:modelValue', block.value)
}

const drag = ref({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0
});

const onDragStart = (e) => {
    e.target.style.background = "#ffffff"
    e.dataTransfer.effectAllowed = "move";
    e.target.style.position = 'absolute';

    drag.value.x = e.screenX;
    drag.value.y = e.screenY;
    drag.value.dx = e.clientX - parseInt(e.target.style.left);
    drag.value.dy = e.clientY - parseInt(e.target.style.top);
}
const onDragEnd = (e) => {
    e.target.style.cursor = "move"
    emit('moveBlock', {
        blockId: block.value.id,
        x: pixelToMM(e.screenX - drag.value.x),
        y: pixelToMM(e.screenY - drag.value.y)
    })
}
const onDrag = (e) => {
    let el = e.target;
    el.style.left = e.clientX - drag.value.dx + 'px';
    el.style.top = e.clientY - drag.value.dy + 'px';
}

const isActive = computed(() => block.value.id === props.activeBlockId)
const onClick = () => {

    if(!isActive.value) emit('activateBlock', block.value.id)
}
const blockStyle = computed(() => {
    return {
        cursor: 'move',
        border: isActive.value ? '1px dashed #42b983' : '1px dashed transparent',
        background: isActive.value ? '#42b98333' : 'transparent',
        position: 'absolute',
        top: mmToPixel(block.value.y) + 'px',
        left: mmToPixel(block.value.x) + 'px',
        width: mmToPixel(block.value.width) + 'px',
        height: mmToPixel(block.value.height) + 'px',
        'line-height': mmToPixel(block.value.height) + 'px',
        'text-align': block.value.align === 'C' ? 'center' : block.value.align === 'R' ? 'right' : 'left',
        'font-size': block.value.font.size + 'pt',
        'font-family': block.value.font.family,
        'color': block.value.font.color ?? 'red',
        'font-weight': block.value.font.style.indexOf('B') > -1 ? 'bold' : 'normal',
        'font-style': block.value.font.style.indexOf('I') > -1 ? 'italic' : 'normal',
    }
});

</script>
<style scoped lang="scss">
.label{
    position: absolute;
    top:0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #2a2f2e;
    font-size: 10px;
    font-weight: normal;
    color: white;
    &.active{
        background-color: #42b983;
    }
}
.hoverBorder:hover{
    border-width: 1px !important;
    border-color: #868686 !important;
}
[contenteditable] {
  outline: 0px solid transparent;
}
</style>
