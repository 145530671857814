<template>
    <div
         :style="blockStyle"
         @click="onClick"
         @dragstart="onDragStart"
         @dragend="onDragEnd"
         @drag="onDrag"
         draggable="true"
    ><img :src="imgSrc" alt="" style="width:100%">
        <div :class="['label', isActive ? 'active' : '']">{{ props.number }}</div>
        <div style="width: 4px; position: absolute; top:0; right:0; background: #4a5568; height: 100%"></div>
    </div>
</template>
<script setup>

import {ref, computed, defineProps, defineEmits} from "vue"
import { pixelToMM, mmToPixel } from "@/libs/utils";

const props = defineProps(['modelValue', 'activeBlockId', 'zoom', 'number', 'basePath'])


const emit = defineEmits(['update:modelValue', 'activateBlock', 'moveBlock']);
const block = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const imgSrc = computed(() => props.basePath + block.value.src)

const onClick = () => emit('activateBlock', block.value.id)

const drag = ref({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0
});

const onDragStart = (e) => {
    e.dataTransfer.effectAllowed = "move";
    e.target.style.position = 'absolute';

    drag.value.x = e.screenX;
    drag.value.y = e.screenY;
    drag.value.dx = e.clientX - parseInt(e.target.style.left);
    drag.value.dy = e.clientY - parseInt(e.target.style.top);
}
const onDragEnd = (e) => {
    e.target.style.cursor = "move"
    emit('moveBlock', {
        blockId: block.value.id,
        x: pixelToMM(e.screenX - drag.value.x),
        y: pixelToMM(e.screenY - drag.value.y)
    })
}
const onDrag = (e) => {
    let el = e.target;
    el.style.left = e.clientX - drag.value.dx + 'px';
    el.style.top = e.clientY - drag.value.dy + 'px';
}

const isActive = computed(() => block.value.id === props.activeBlockId)
const blockStyle = computed(() => {
    return {
        cursor: 'move',
        border: isActive.value ? '1px dashed #42b983' : '1px dashed #868686',
        background: isActive.value ? '#42b98333' : 'transparent',
        position: 'absolute',
        top: mmToPixel(block.value.y) + 'px',
        left: mmToPixel(block.value.x) + 'px',
        width: mmToPixel(block.value.width) + 'px',
        height: mmToPixel(block.value.height) + 'px',
    }
});

</script>
<style scoped lang="scss">
.label{
    position: absolute;
    top:0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #2a2f2e;
    font-size: 10px;
    font-weight: normal;
    color: white;
    &.active{
        background-color: #42b983;
    }
}
</style>
