<template>
    <div class="text-left">
        <h1 ref="wer">список проектов</h1>
        <div class="datatable-wrapper">
            <PrimeTree :value="nodes" class="w-full" @contextmenu="onEmptyRightClick">
                <template #project="slotProps">
                    <span @contextmenu="onProjectRightClick($event, slotProps.node.data)">{{ slotProps.node.label }}</span>
                </template>
                <template #folder="slotProps">
                    <span @contextmenu="onFolderRightClick($event, slotProps.node.data)">{{ slotProps.node.label }}</span>
                </template>

            </PrimeTree>
            <ContextMenu ref="menuProject" :model="projectMenuItems" />
            <ContextMenu ref="menuFolder" :model="folderMenuItems" />
            <ContextMenu ref="menuEmpty" :model="emptyMenuItems" />
            <ConfirmDialog group="createFolderDialog">
                <template #message="">
                    <div class="flex p-4">
                        <InputText v-model="newFolderName" placeholder="Название папки"></InputText>
                    </div>
                </template>
            </ConfirmDialog>
            <ConfirmDialog group="renameFolderDialog">
                <template #message="">
                    <div class="flex p-4">
                        <InputText v-model="newFolderName" placeholder="Название папки"></InputText>
                    </div>
                </template>
            </ConfirmDialog>
            <ConfirmDialog group="deleteFolderDialog"></ConfirmDialog>

            <ConfirmDialog group="replaceProjectDialog">
                <template #message="">
                    <div class="flex p-4">
                        <TreeSelect v-model="replaceFolderId" :options="folders" placeholder="Выберите папку" class="md:w-20rem w-full" />
                    </div>
                </template>
            </ConfirmDialog>

        </div>

        <PrimeButton @click="addProject" class="mt-3">Добавить проект</PrimeButton>

        <PrimeDialog header="Создаем новый проект" v-model:visible="showDialogForm" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
            <p>Введите название проекта</p>
            <InputText v-model="newProject.name" placeholder="введите название проекта" class="w-full"></InputText>
            <p>Ориентация</p>
            <PrimeDropdown v-model="newProject.template.orientation" @change="onOrientationChange" class="p-dropdown-sm" optionValue="value" optionLabel="label" :options="orientations"  />
            <p>Размер изображения: {{imageSize}}</p>

            <PrimePanel header="Шаблон проекта" class=" mt-3">
                <div class="flex py-2">
                    <div class="preview" >
                        <img :src="templateImg"  alt="" v-if="templateImg !==null">
                    </div>
                    <div class="ml-2">
                        <PrimeButton @click="selectTemplateBg" class="mr-3  p-button-sm">Выбрать из библиотеки</PrimeButton>
                        <FileUpload
                            class="p-button-sm mt-2"
                            @select="selectFile" mode="basic" name="demo[]"  custom-upload upload="upload" :multiple="false" accept="image/*" :maxFileSize="1000000" :show-cancel-button="false" :show-upload-button="false" chooseLabel="Свой файл"></FileUpload>
                    </div>

                </div>

            </PrimePanel>

            <template #footer>
                <PrimeButton label="Отмена" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
                <PrimeButton label="Создать" icon="pi pi-check" @click="createProject" autofocus />
            </template>
        </PrimeDialog>


        <div class="text-right">


            <ConfirmDialog></ConfirmDialog>


        </div>
    </div>
    <TemplateBGSelector
        class="my-bg"
        v-show="templateSelectorVisible"
        @select="onSelectBG"
        @close="templateSelectorVisible=false"/>
</template>

<script setup>
import api from "@/libs/api";
import {ref, computed } from "vue";
import { useRouter } from 'vue-router'
import { useConfirm } from "primevue/useconfirm";
import TemplateBGSelector from "@/components/TemplateBGSelector";
import ContextMenu from 'primevue/contextmenu';

const menuFolder = ref();
const menuProject = ref();
const menuEmpty = ref();
const nodes = ref(null);

const selectedFolder = ref(null);
const selectedProject = ref();

const newFolderName = ref();

const reload = () => load();

const createFolder = () => {
    newFolderName.value = '';
    confirm.require({
        group: 'createFolderDialog',
        header: 'Создать папку',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-primary',
        acceptLabel: 'Создать',
        rejectLabel: 'Отмена',
        accept: () => {
            let parentId = selectedFolder.value !== null ? selectedFolder.value.id : null;
            api.createFolder(newFolderName.value, parentId).then(() => reload());
        },
        reject: () => {
            console.log('отмена');
        }
    });
}

const deleteFolder = () => {
    confirm.require({
        group: 'deleteFolderDialog',
        header: 'Удалить папку',
        message: 'Вы действительно хотите удалить папку "' + selectedFolder.value.name + '"',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Удалить',
        rejectLabel: 'Отмена',
        accept: () => {
            api.deleteFolder(selectedFolder.value.id).then(() => reload());
        },
        reject: () => {
            console.log('отмена');
        }
    });
}

const renameFolder = () =>{
    newFolderName.value  = selectedFolder.value.name;
    confirm.require({
        group: 'renameFolderDialog',
        header: 'Переименовать папку',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-primary',
        acceptLabel: 'Переименовать',
        rejectLabel: 'Отмена',
        accept: () => {
            api.renameFolder(selectedFolder.value.id, newFolderName.value).then(() => reload());
        },
        reject: () => {
            console.log('отмена');
        }
    });
}

const replaceFolderId = ref();

const folders = computed(() =>{
    let arr = onlyFolders(Object.assign([],nodes.value));
    arr.unshift({
        key:null,
        label:"корневая директория",
        icon: 'pi pi-fw pi-folder',
        type: 'folder'
    })


    return arr
})

const onlyFolders = (items) => {
    let folders = items.filter(node => node.type === 'folder');
    if(folders.length === 0) return [];
    return folders.map(folder => {
        let obj = {...folder};
        obj.key = obj.data.id;
        obj.children = onlyFolders(obj.children);
        return obj;
    })
}

const replaceProject = () => {
    confirm.require({
        group: 'replaceProjectDialog',
        header: 'Переместить проект',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-primary',
        acceptLabel: 'Переместить',
        rejectLabel: 'Отмена',
        accept: () => {
            api.replaceProject(selectedProject.value.id, Object.entries(replaceFolderId.value)[0][0] ?? undefined).then(() => reload());
        },
        reject: () => {
            console.log('отмена');
        }
    });
}
const editProject = () => {
    router.push({ name: 'project_edit', params: { project_id: selectedProject.value.id } })
}

const copyOne = () => {
    api.copyProject(selectedProject.value.id).then(() => reload());
}

const doDelete = (id) => api.deleteProject(id).then( () => reload())
const deleteProject = () =>{
    confirm.require({
        message: 'Вы действительно хотите удалить проект ' + selectedProject.value.name,
        header: 'Подтвердите удаление',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Удалить',
        rejectLabel: 'Отмена',
        accept: () => {
            doDelete(selectedProject.value.id)
        },
        reject: () => {

        }
    });
}




const confirm = useConfirm();


const uploadedFiles = ref({});

const  orientations = ref([
    {label: 'Портретная', value: 'P'},
    {label: 'Альбомная', value: 'L'},

]);


const templateSelectorVisible = ref(false);
const selectTemplateBg = () =>{
    templateSelectorVisible.value = true;
}

const  onOrientationChange = () => {
    newProject.value.template.width = newProject.value.template.orientation === 'L' ? 297 : 210;
    newProject.value.template.height = newProject.value.template.orientation === 'L' ? 210 : 297;
}



const newProjectObjTemplate = {
    name: '',
    template:{
        orientation: 'L',
        unit: 'mm',
        size: 'A4',
        width: 297,
        height: 210,
        background_file: null,
        blocks: "[]"
    }
};
const router = useRouter()

const selectedTemplate = ref({});


const templateImg = computed(() => {
    if(selectedTemplate.value !== {}) return "/backgrounds/" + selectedTemplate.value.preview;

    if(uploadedFiles.value[0] !== undefined) return URL.createObjectURL(uploadedFiles.value[0])

    return null;

});

const newProject = ref(newProjectObjTemplate);

const selectFile = (e) => { uploadedFiles.value = e.files }
const onSelectBG = (item) => {
    selectedTemplate.value = item;
    templateSelectorVisible.value = false;
}


const resetNewProject = () => { newProject.value  = newProjectObjTemplate }



const showDialogForm = ref(false);
const closeDialog = () => {
    selectedTemplate.value = {};
    uploadedFiles.value = {};
    showDialogForm.value = false;
    resetNewProject();
}
const addProject = () => {
    showDialogForm.value = true
}

const imageSize = computed(() => newProject.value.template.orientation === 'L' ? '1126 x 796' : '796 x 1126')


const setTemplateImage = (project) => {
    if(selectedTemplate.value !== {}){
        return api.copyTemplateImageFromLibrary(project.id, selectedTemplate.value.id);
    } else {
        return  api.uploadProjectTemplateImage(project.id, uploadedFiles.value);
    }
}

const createProject = () => {
    let parentFolderId = selectedFolder.value !== null ? selectedFolder.value.id : null;
    api.createProject(newProject.value.name, parentFolderId).then( project => {
        setTemplateImage(project).then( data => {
            newProject.value.template.background_file = data.filename;
            api.createProjectTemplate(project.id, newProject.value.template).then(
                () => router.push({ name: 'project_edit', params: { project_id: project.id } })
            )
        })
        newProject.value.template.name = 'Шаблон для ' + project.name
        api.createProjectTemplate(project.id, newProject.value.template)
    });
};





const load = () => api.getFoldersAndProjects().then(data => {
    data = data.map(item => {
        let d = new Date(item.created_at);
        let ye = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('ru', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(d);
        let ho = new Intl.DateTimeFormat('ru', { hour: '2-digit' }).format(d);
        let mi = new Intl.DateTimeFormat('ru', { minute: '2-digit' }).format(d);

        item.created_at = `${da} ${mo} ${ye} ${ho}:${mi}`

        return getNodeFromItem(item);

    });
    nodes.value = data;
})

const getNodeFromItem = (item) => {
    if(item.guid !== undefined){
        return {
            key: 'p-' + item.id,
            label: item.name,
            data: item,
            icon: 'pi pi-fw pi-book',
            type: 'project'
        }
    } else {

        return {
            key: 'f-' + item.id,
            label: item.name,
            data: item,
            icon: 'pi pi-fw pi-folder',
            type: 'folder',
            children: getChildren(item)
        }
    }
}

const getChildren = (node) => {
    if (node.children.length == 0) return [];
    return node.children.map(item => {
        return getNodeFromItem(item);
    });
}


const emptyMenuItems =  ref([
    { label: 'Создать папку', icon: 'pi pi-fw pi-folder', command: createFolder},
    { label: 'Создать проект', icon: 'pi pi-fw pi-file', command: addProject },
])

const projectMenuItems =  ref([
    { label: 'Копировать', icon: 'pi pi-fw pi-copy', command: copyOne },
    { label: 'Редактировать', icon: 'pi pi-fw pi-pencil', command: editProject },
    { label: 'Переместить', icon: 'pi pi-fw pi-directions', command: replaceProject },
    { label: 'Удалить', icon: 'pi pi-fw pi-trash', command: deleteProject }
])

const folderMenuItems =  ref([
    { label: 'Создать папку', icon: 'pi pi-fw pi-folder', command: createFolder },
    { label: 'Создать проект', icon: 'pi pi-fw pi-file', command: addProject },
    { label: 'Переименовать', icon: 'pi pi-fw pi-pencil', command: renameFolder  },
    { label: 'Удалить', icon: 'pi pi-fw pi-trash', command: deleteFolder }
])

const onEmptyRightClick = (event) => {
    selectedFolder.value = null;
    event.stopPropagation();
    menuFolder.value.hide();
    menuProject.value.hide();


    menuEmpty.value.show(event);
}

const onProjectRightClick = (event, item) => {
    selectedProject.value = item;
    event.stopPropagation();
    menuFolder.value.hide();
    menuEmpty.value.hide();

    menuProject.value.show(event);
}

const onFolderRightClick = (event, item) => {
    selectedFolder.value = item;
    event.stopPropagation();
    menuProject.value.hide();
    menuEmpty.value.hide();


    menuFolder.value.show(event);
};

load();



</script>

<style lang="scss">
.preview{
    width: 150px;
    height: 150px;
    background: lightgray;
    img{
        max-width: 150px;
        max-height: 150px;
        margin: auto;
        display: block;
        padding: 10px;
    }
}
.my-bg {
    .window{
        z-index: 10400 !important;
    }
}
.datatable-wrapper{
    max-height: 80vh;
    overflow-y: auto;
}
</style>
