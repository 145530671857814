<template>


    <div class="wrapper">
        <div class="layout relative" :style="layoutStyle" >
            <canvas style="position:absolute; top:0; left:0;" width="796" height="1126" id="can1"></canvas>
            <template v-for="(block,idx) in blocks">
                <template v-if="block.type === 'text'">
                    <TextBlockWidget v-model="blocks[idx]" :activeBlockId="-1"
                                     :zoom="zoom" :number="idx+1" :key="block.id"
                                     :editMode="false"
                    />
                </template>
                <template v-if="block.type === 'img'">
                    <ImgBlockWidget v-model="blocks[idx]" :activeBlockId="-1"
                                    :zoom="zoom" :number="idx+1" :key="block.id"
                                    :basePath="projectTemplateImagePath"
                    />
                </template>

            </template>


        </div>
    </div>
</template>

<script setup>
import TextBlockWidget  from "@/components/TextBlockWidget";
import ImgBlockWidget  from "@/components/ImageBlockWidget";
import api from "@/libs/api";
import {defineComponent, defineProps,  ref, computed} from "vue";

defineComponent(TextBlockWidget);
defineComponent(ImgBlockWidget);



const props = defineProps(['project','dataSet','template']);
const zoom = ref(1);

const pixelsInSm = 3.79;
const mmToPixel = (mm, zoom) => Math.round(mm * pixelsInSm * zoom);



const projectTemplateImagePath = computed(() =>{
    return props.project !== undefined ? api.server + '/projects/' + props.project.guid+ '/images/'  : '';
})


// const templateInner = ref({
//     get: () => props.template,
// })

const replaceWildCards = (block, data) =>{
    let b = {...block};
    if (b.type === "text" && b.text.indexOf("%") > -1) {
        let fields = b.text.split('%').filter((item, idx) => idx % 2);
        let resultText = b.text;
        fields.forEach(field => {
            resultText = resultText.replace(`%${field}%`, data[field]);
        })
        b.text = resultText;

    }
    return b;
}

const blocks = computed(() =>  {
    return props.template.blocks.map(block => replaceWildCards(block, props.dataSet))
});


const layoutStyle = computed(() => {
    if(props.template === null) return '';
    return {
        position: 'relative',
        margin: 'auto',
        width: mmToPixel(props.template.width, 1) + 'px',
        height: mmToPixel(props.template.height, 1) + 'px',
        background: props.template.background_file !== undefined ? 'url("'+projectTemplateImagePath.value + props.template.background_file + '") no-repeat #fff' : '#fff',
        'background-size' : 'cover'
    }

});



</script>

<style scoped lang="scss">
.wrapper{
    background-color: #4a5568;
    padding: 10px;
}
.layout{
    background-color: #fff;
}

</style>
