<template>
  <div>
    <div class="flex flex-column gap-5">
      <div class="flex align-items-center gap-2">
        Публичный доступ: <InputSwitch size="small" v-model="publicAccess.allow" @change="save" />
      </div>
      <div>
        Поле, используемое как код доступа
        <PrimeDropdown style="min-width: 300px" placeholder="выберите поле" v-model="publicAccess.access_field" optionLabel="name"  optionValue ="column" :options="fields" @change="save"></PrimeDropdown>
      </div>
      <div>
       <PrimeTextArea v-model="publicAccess.access_comment" @change="save" style="min-width: 300px" cols="72" rows="7">

       </PrimeTextArea>
      </div>
      <div>
        <PrimeButton @click="loadAccessSheet">Скачать список со ссылками</PrimeButton>
      </div>


    </div>

  </div>
</template>

<script setup>
import {defineProps, ref, defineEmits} from "vue";
import api from "@/libs/api";

const emit = defineEmits(['changeAccessOptions']);
const props = defineProps(['project']);

const publicAccess =ref({
  allow: props.project.public===1 ,
  access_field: props.project.password_field,
  access_comment: props.project.access_comment
});
const fields =ref([]);

const save = () =>{
  api.updateProjectPublicAccess(props.project.id,
      publicAccess.value.allow,
      publicAccess.value.access_field,
      publicAccess.value.access_comment).then(
      (data) => {
      emit('changeAccessOptions', publicAccess.value);
      console.log('saved', data);
      }
  )
}

const loadAccessSheet = () => {
  api.loadAccessSheet(props.project.id).then(
      (data) => {
        emit('changeAccessOptions', publicAccess.value);
        console.log('saved', data);
      }
  )
}

const load = () => {

  api.viewProjectData(props.project.id).then(data => {
    fields.value = data.keys

  });
}
load();



</script>

<style scoped lang="scss">
.active-row{
  background-color: #abf1b9 !important;
}
.stripped > div:nth-child(odd){
  background-color: #f5f5f5;
}
</style>
