<template>
    <div>
        <div class="flex">
            <div class="col-9" style="height: calc(100vh - 185px); overflow-y: scroll">

                <TemplatePreviewReadOnly :data-set="dataSet" :project="props.project" :template="projectTemplate"></TemplatePreviewReadOnly>
            </div>
            <div class="col-3" style="height: calc(100vh - 185px); overflow-y: scroll">
                <div>Отображать поле: </div>
                <select name="" v-model="currentField" id="">
                    <option :value="key.column" v-for="key in projectData.keys" :key="key.column">{{key.name}}</option>
                </select>
                <div class="mt-3 stripped">
                    <div v-for="(item, idx) in projectData.rows" :key="idx" style="cursor: pointer; color: #3e3e3e;" :class="['flex p-2',{'active-row': item.row === currentRow}]" @click="onRowClick(item.row)">
                        <div class="mr-2">{{item.row}}</div>
                        <div>{{item[currentField]}}</div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script setup>
import {ref,  computed, defineComponent, defineProps} from "vue";
import api from "@/libs/api";
import TemplatePreviewReadOnly from "@/components/TemplatePreviewReadOnly";
import {useRoute} from "vue-router";
import moment from "moment";
defineComponent(TemplatePreviewReadOnly);

const props = defineProps(['project']);
const projectData = ref({
    kes: [],
    rows: []
});
const currentRow = ref(1);
const currentField = ref('c2');
const onRowClick = (row) => currentRow.value = row;

const dataSet = computed(() => {
    return projectData.value.rows.filter(item => item.row === currentRow.value).map(data => {
        let newSet = {
            row: data['row'],
            'system.datetime': moment().format('DD.MM.YYYY h:mm:ss'),
            'system.time': moment().format('h:mm:ss'),
            'system.date': moment().format('DD.MM.YYYY'),
        };

        projectData.value.keys.map( key => {
            newSet[key.name] = data[key.column];
        })
        return newSet
    })[0];
})

const loading = ref(false);
const project_id = ref(useRoute().params.project_id);



const projectTemplate = ref({blocks:[]});

const load = () => {
    loading.value = true;
    api.viewProjectData(project_id.value).then(data => {
        projectData.value.keys = data.keys;
        projectData.value.rows = data.values;
        api.viewProjectTemplate(project_id.value).then(data => {
            data.blocks = JSON.parse(data.blocks).map(block => {
                if(block.background === undefined) block.background = "";
                if(block.border_width === undefined) {
                    block.border_width = 0;
                } else {
                    block.border_width = parseInt(block.border_width);
                }
                if(block.border_color === undefined) block.border_color = "#000000";
                return block;
            });
            projectTemplate.value = data
            loading.value = false;
        })

    });
}

load();



</script>

<style scoped lang="scss">
.active-row{
    background-color: #abf1b9 !important;
}
.stripped > div:nth-child(odd){
    background-color: #f5f5f5;
}
</style>
