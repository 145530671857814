const pixelsInSm = 3.79;
const round = (val, decimal) => {
    decimal = decimal ?? 0;
    let mul = 10**decimal;
    return Math.round( val * mul  )/mul;    
}

const mmToPixel = (mm, zoom) => {
    zoom = zoom ?? 1;
    return round(mm * pixelsInSm * zoom, 2);    
}
const pixelToMM = (pixel, zoom) => {
    zoom = zoom ?? 1;
    return round( (pixel * zoom )/pixelsInSm, 2);    
};    


export {
    round,
    pixelsInSm,
    pixelToMM,
    mmToPixel
}