<template>
    <div id="ProjectEditor" class="text-left" >
        <InputText v-model="project.name" @change="onChangeName" class="w-full border-0 focus:border-50 text-2xl"></InputText>
        <div class="mt-3">
            <TabView @tab-change="onTabChange" class="tab-view-custom">
                <TabPanel header="Шаблон" >
                    <ProjectTemplateManager v-if="mode==='template'" :project="project" ></ProjectTemplateManager>
                </TabPanel>
                <TabPanel header="Данные" >
                    <ProjectDataManager  v-if="mode==='data'" :project="project"></ProjectDataManager>
                </TabPanel>
                <TabPanel header="Предварительные просмотр" >
                    <ProjectPreview  v-if="mode==='preview'" :project="project" ></ProjectPreview>
                </TabPanel>
              <TabPanel header="Публичный доступ" >
                <ProjectPublicAccess  v-if="mode==='access'" :project="project"  @changeAccessOptions="onChangeAccessOptions"></ProjectPublicAccess>
              </TabPanel>
            </TabView>
        </div>
    </div>

</template>

<script>
import api from "@/libs/api";

import ProjectTemplateManager from "@/components/ProjectTemplateManager";
import ProjectDataManager from "@/components/ProjectDataManager";
import ProjectPreview  from "@/components/ProjectPreview";
import ProjectPublicAccess from "@/components/ProjectPublicAccess.vue";

export default {
    components:{
      ProjectTemplateManager,
      ProjectDataManager,
      ProjectPreview,
      ProjectPublicAccess
    },
    data(){
        return {
            mode: 'template',
            project_id: null,
            project: {
                name: ''
            },
        }
    },
    methods:{
        onTabChange(e){
            switch (e.index){
                case 0:
                    this.mode =  'template';
                    break;

                case 1:
                    this.mode =  'data';
                    break;

                case 2:
                    this.mode =  'preview';
                    break;

                case 3:
                  this.mode =  'access';
                  break;
            }
        },

        onChangeAccessOptions(options){
          this.project.public = options.allow ? 1 : 0;
          this.project.password_field = options.access_field;
          this.project.access_comment = options.access_comment;
        },
        onChangeName(){
            api.updateProjectName(this.project_id, this.project.name)
        }
    },
    computed:{
      description(){
          return this.project !== null ? JSON.stringify(this.project) : '';
      }
    },
    mounted() {
        this.project_id = this.$route.params.project_id;
        api.viewProject(this.project_id).then(data => this.project = data);


    }
}
</script>

<style lang="scss">
    .p-tabview.tab-view-custom{
        color: red;
        .p-tabview-panels {
            padding: 1rem 0;
        }
    }

</style>
