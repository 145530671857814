<template>
    <div :class="['text-block-widget', active ? 'active' : '' ]">

        <div class="short-view cursor-pointer" @click="setActive">
            <div class="flex">
                <div class="text-block-number">{{number}}</div>
                <div class="px-1">{{modelValue.text}}</div>

            </div>
        </div>
        <div class="full-view" v-if="active">
            <div class="grid">
                <div class="col">
                    <div>x: {{theModel.x}}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.x"  :min="0" :max="300"  />
                    </div>
                </div>
                <div class="col">
                    <div>y: {{theModel.y}}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.y"  :min="0" :max="300"  />
                    </div>
                </div>
            </div>
            <div class="row grid">
                <div class="col">
                    <div>ширина текста: {{theModel.width}}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.width"  :min="0" :max="200"  />
                    </div>
                </div>
                <div class="col">
                    <div>высота строки: {{theModel.height}}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.height"  :min="0" :max="100"  />
                    </div>
                </div>
            </div>
            <div class="p-1"><InputText  v-model="theModel.text"  class="p-inputtext-sm w-full" inputStyle="width:100%"/></div>

            <div class="p-1 grid">
                <div class="col-6" >
                    <SelectButton v-model="theModel.align" :options="justifyOptions" optionLabel="value" optionValue="value" dataKey="value" aria-labelledby="custom" >
                        <template #option="slotProps">
                            <i :class="slotProps.option.icon"></i>
                        </template>
                    </SelectButton>
                </div>
                <div class="col">
                    <SelectButton v-model="fontStyle" :options="fontStyleOptions"  multiple optionLabel="label" optionValue="value" optionDisabled="disable" dataKey="value" aria-labelledby="custom" class="font-style-selector">

                        <template #option="slotProps" >
                            <span v-html="slotProps.option.label" :style="slotProps.option.style" ></span>
                        </template>
                    </SelectButton>
                </div>


            </div>
            <div class="p-1">
                <PrimeDropdown v-model="theModel.font.family" class="p-dropdown-sm mr-1" :options="fontFamilies" />
                    <PrimeDropdown v-model="theModel.font.size" class="p-dropdown-sm mr-1" :options="fontSizes" />
                    <ColorPicker v-model="fontColor" class="mr-1 p-colorpicker-sm"/>
                    <InputText v-model="fontColor" style="width: 80px" class="p-inputtext-sm" ></InputText>
            </div>
            <div class="p-1">
                    <label class="property-name">Заливка</label>
                    <ColorPicker v-model="bgColor" />
                    <InputText v-model="bgColor" class="p-inputtext-sm" style="width: 80px"></InputText>
            </div>
            <div class="p-1">
                    <label class="property-name">Рамка</label>
                    <ColorPicker v-model="borderColor" class="mr-1"/>
                    <InputText v-model="borderColor" class="p-inputtext-sm mr-1" style="width: 80px"></InputText>
                    <label for="a" class="inline-block mr-1">ширина </label>
                    <InputNumber v-model="theModel.border_width" inputStyle="width: 40px;font-size: 0.875rem; padding: 0.4375rem 0.4375rem;" /><label for="s" class="ml-1">px</label>
            </div>
            <div class="text-right pt-2">
                <ConfirmDialog></ConfirmDialog>
                <PrimeButton @click="deleteBlock" icon="pi pi-times p-button-sm" iconPos="right" label="Удалить" class="p-button-danger p-button-outlined"/>

            </div>
        </div>

    </div>
</template>

<script setup>
import {ref, computed, defineProps, defineEmits} from "vue";
import { useConfirm } from "primevue/useconfirm";

    const confirm = useConfirm();
    const emit = defineEmits(['update:modelValue','deleteBlock', 'clickBlock'])
    const props = defineProps(['modelValue','number', 'active', 'fonts']);



    const deleteBlock = () => {
        confirm.require({
            message: 'Вы действительно хотите удалить текстовый блок',
            header: 'Подтвердите удаление',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                emit('deleteBlock', props.modelValue.id)
            },
            reject: () => {

            }
        });

    }

    const theModel= computed({
        get: () => props.modelValue,
        set: (value) => {
            emit('update:modelValue', value)
        }
    })

    const fontColor = computed({
        get: () => props.modelValue.font.color.replace('#',''),
        set: (value) => {
            theModel.value.font.color = '#' + value;
            emit('update:modelValue', theModel.value)
        }
    })

    const borderColor = computed({
        get: () => props.modelValue.border_color.replace('#',''),
        set: (value) => {
            theModel.value.border_color = '#' + value;
            emit('update:modelValue', theModel.value)
        }
    })

    const bgColor = computed({
        get: () => props.modelValue.background.replace('#',''),
        set: (value) => {
            theModel.value.background = '#' + value;
            emit('update:modelValue', theModel.value)
        }
    })



    const fontStyle = computed({
        get: () => props.modelValue.font.style.split(''),
        set: (value) => {
            theModel.value.font.style =  value.join('');
            emit('update:modelValue', theModel.value)
        }
    })

    const fontFamilies = computed(() => {
        return props.fonts.map( font => font.family)
    });
    const selectedFont = computed(() => {

        let selected = props.fonts.filter(font => font.family === theModel.value.font.family);
        if (selected == []) return '';
        selected = selected[0];
        return {
            family: theModel.value.font.family,
            hasBoldSignature:  selected.styles.indexOf('b') > -1,
            hasItalicSignature:  selected.styles.indexOf('i') > -1,
            hasBoldItalicSignature: selected.styles.indexOf('bi') > -1,
        }
    });

    const boldAvailable = computed(() => {
        if(selectedFont.value === '')return false;
        let style = theModel.value.font.style;
        return (selectedFont.value.hasBoldSignature && (style === "" || style === "b" || style === "bi"  ) )
                ||  (selectedFont.value.hasBoldItalicSignature && (style === "i" || style === "bi"));

    });

    const italicAvailable = computed(() => {
        if(selectedFont.value === '')return false;
        let style = theModel.value.font.style;
        return (selectedFont.value.hasItalicSignature && (style === "" || style === "i" || style === "bi"  ) )
                ||  (selectedFont.value.hasBoldItalicSignature && (style === "b" || style === "bi"));

    });

    const fontStyleOptions = computed(() => [
        {label: 'В', style:'font-weight: bold; line-height: 16px', value: 'B', disable: !boldAvailable.value},
        {label: 'I', style:'font-weight: bold; font-style: italic; line-height: 16px',value: 'I', disable: !italicAvailable.value},
    ]);
    const fontSizes = ref([
        8,10,11,12,13,15,16,18,20,24,28,30,32,36,40,42,38,50
    ]);
    const justifyOptions = ref([
        {icon: 'pi pi-align-left', value: 'L'},
        {icon: 'pi pi-align-center', value: 'C'},
        {icon: 'pi pi-align-right', value: 'R'},
    ]);

    const setActive = () => {
        if(props.active === theModel.value.id) return;
        emit('clickBlock', theModel.value.id)
    }


</script>

<style scoped lang="scss">
.text-block-widget{
    border: 1px solid #868686;
    margin: 3px 0;
    border-radius: 4px;
    .text-block-number{
        background-color: #2a2f2e;
        width: 20px;
        color: white;
        text-align: center;

    }
    .full-view{
        padding: 5px;
        background-color: #f8f8f8;
    }
    &.active{
        border: 1px solid #42b983;
        .text-block-number{
            background-color: #42b983;
        }
    }
}
.font-style-selector{
    .p-button{

    }
}
label.property-name{
    width: 70px;
    display: inline-block;
}
</style>
