<template>
    <div>

        <div id="login-form-wrapper" v-if="!isAuthorized" class="flex align-content-center flex-wrap">
            <div class="login-form m-auto"  style="max-width: 350px;">
                <img src="/img/logo.png" alt="">

                <div class="p-inputgroup">
                    <InputText placeholder="Email" v-model="loginForm.username"/>
                </div>

                <div class="p-inputgroup mt-2">

                    <InputText placeholder="Пароль"  type="password" v-model="loginForm.password" @keydown.enter="onSubmitForm" />
                </div>
                <div class="flex align-content-start mt-2">
                    <PrimeButton label="Войти" @click="onSubmitForm"/>
                </div>


            </div>
        </div>

        <div id="page-wrapper" v-else>


            <div id="side-menu">
                <div id="logo">
                    <a href="/"><img src="/img/logo.png" alt=""></a>
                </div>
                <SideMenu></SideMenu>
            </div>
            <div id="content-placeholder">
                <!--div id="menu-navbar">
                    top menu
                </div-->
                <div id="content-wrapper">
                    <router-view/>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

import SideMenu from "@/components/SideMenu";
import EventBus from "@/libs/event-bus";
import events from "@/libs/events";


import {user} from "@/libs/auth"

export default {
    name: 'App',
    data() {
        return {
            isAuthorized: false,
            loginForm:{
                username: '',
                password: ''
            }
        }
    },
    components: {
        SideMenu
    },
    methods:{
        onSubmitForm(){
            console.log('on submit form', {...this.loginForm});
            user.login(this.loginForm.username, this.loginForm.password).
            then(response => {
                if(response.status === 200) this.isAuthorized = true;
            })
        }
    },
    mounted() {
        this.isAuthorized = user.isAuthorized();
        EventBus.$on(events.STATUS_401_UNAUTHORIZED, () =>{
            this.isAuthorized = false;
        });
        EventBus.$on(events.LOGOFF, () =>{
            this.isAuthorized = false;
            console.log('log off')
            window.location.href="/";
        });

    }
}
</script>

<style lang="scss">
body{
    margin: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#login-form-wrapper{
    width: 100%;
    height: 100%;
    display: table;

    min-width: 320px !important;
    min-height: 100vh;
}
#page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    min-width: 320px !important;
    min-height: 98vh;
}

#side-menu {

    position: relative;
    min-height: 100vh;
    width: 250px;
    height: 100%;

    vertical-align: top;
    background-color: #0059a9;
    -webkit-box-shadow: -1px 0 0 #0059a9;
    box-shadow: -1px 0 0 #0059a9;
    flex-shrink: 0;
}

#content-placeholder {

    margin-left: 0;
    width: 100%;
    overflow: auto;
    //display: table-cell;
    position: relative;
    min-height: 100%;
}

#menu-navbar {
    box-shadow: 1px 2px 10px rgb(0 0 0 / 4%);
    margin-bottom: 0;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    padding-left: 26px;
    padding-right: 24px;
    position: relative;
    min-height: 45px;
}

#logo {
    box-shadow: 1px 2px 10px rgb(0 0 0 / 4%);
    margin-bottom: 0;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    padding-left: 26px;
    padding-right: 24px;
    position: relative;
    min-height: 45px;
}

#content-wrapper {
    margin: 28px 32px 0 32px;
    min-height: 72vh;
    box-sizing: border-box;
}
</style>
