<template>
    <div :class="['block-widget', active ? 'active' : '']">

        <div class="short-view cursor-pointer" @click="setActive">
            <div class="flex">
                <div class="block-number">{{ number }}</div>
                <div class="px-1">{{ modelValue.text }}</div>

            </div>
        </div>
        <div class="full-view" v-if="active">
            <div class="grid">
                <div class="col">
                    <div>x: {{ theModel.x }}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.x" :min="0" :max="300" />
                    </div>
                </div>
                <div class="col">
                    <div>y: {{ theModel.y }}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModel.y" :min="0" :max="300" />
                    </div>
                </div>
            </div>
            <div class="row grid">
                <div class="col">
                    <div>w: {{ theModelWidth }}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModelWidth" :min="0" :max="200" />
                    </div>
                </div>
                <div class="col">
                    <div>h: {{ theModelHeight }}</div>
                    <div class="pt-2">
                        <PrimeSlider v-model="theModelHeight" :min="0" :max="100" />
                    </div>
                </div>
            </div>
            <div class="p-1">
                <img src="imgSrc" alt="">
            </div>
            <div class="text-right">
                <ConfirmDialog></ConfirmDialog>
                <PrimeButton @click="deleteBlock" icon="pi pi-times" iconPos="right" label="Удалить"
                    class="p-button-danger p-button-outlined" />
            </div>
        </div>

    </div>
</template>

<style lang='scss' scoped>
.block-widget {
    border: 1px solid #868686;
    margin: 3px 0;
    border-radius: 4px;

    .block-number {
        background-color: #2a2f2e;
        width: 20px;
        color: white;
        text-align: center;

    }

    .full-view {
        padding: 5px;
        background-color: #f8f8f8;
    }

    &.active {
        border: 1px solid #42b983;

        .block-number {
            background-color: #42b983;
        }
    }
}
</style>

<script setup>

import { defineProps, defineEmits, computed } from "vue";

const props = defineProps(['modelValue', 'activeBlockId', 'active', 'number']);
const emit = defineEmits(['update:modelValue', 'deleteBlock', 'clickBlock'])

const theModel = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const theModelHeight = computed({
    get: () => theModel.value.height,
    set: (value) => {
        theModel.value.height = value
        if (theModel.value.keepProportional === 1) {
            theModel.value.width = theModel.value.rate * value
        }
        emit('update:modelValue', theModel.value)
    }
})

const theModelWidth = computed({
    get: () => theModel.value.width,
    set: (value) => {
        theModel.value.width = value
        if (theModel.value.keepProportional === 1) {
            theModel.value.height = value / theModel.value.rate
        }
        emit('update:modelValue', theModel.value)
    }
})


const setActive = () => {
    if (props.active === theModel.value.id) return;
    emit('clickBlock', theModel.value.id)
}

const deleteBlock = () => {
    confirm.require({
        message: 'Вы действительно хотите удалить текстовый блок',
        header: 'Подтвердите удаление',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emit('deleteBlock', props.modelValue.id)
        },
        reject: () => {

        }
    });

}

</script>