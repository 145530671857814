import { createRouter, createWebHistory } from 'vue-router'

import ProjectsView from "@/views/ProjectsView";
import ProjectEditView from "@/views/ProjectEditView";
import HomeView from "@/views/HomeView";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/projects/:project_id',
            name: 'project_edit',
            component: ProjectEditView
        },
        {
            path: '/projects',
            name: 'projects',
            component: ProjectsView
        },
    ]
})

export default router
