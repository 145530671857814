export default {
    list(api){
        return api.send.get('projects');
    },

    listWithFolders(api){
        return api.send.get('projectsWithFolders');
    },

    deleteFolder(api, folder_id){
        return api.send.delete('folders/'+folder_id);
    },

    updateFolderName(api, folder_id, folderName){
        return api.send.put('folders/'+folder_id,{name:  folderName});
    },

    createFolder(api, folderName, parentFolderId){
        return api.send.post('folders',{name:  folderName, parent: parentFolderId});
    },

    view(api, project_id){
        return api.send.get('projects/'+project_id);
    },

    deleteProject(api, project_id){
        return api.send.delete('projects/'+project_id);
    },

    updateProjectName(api, project_id, projectName){
        return api.send.put('projects/'+project_id,{name:  projectName});
    },
    updateProjectPublicAccess(api, project_id, allowAccess, passwordField, accessComment){
        return api.send.put('projects/'+project_id,{public:  allowAccess, password_field: passwordField, access_comment: accessComment});
    },

    replaceProject(api, project_id, folder_id){
        return api.send.post(`projects/${project_id}/replace`,{folder_id: folder_id});
    },

    copyProject(api, project_id){
        return api.send.post(`projects/${project_id}/copy`);
    },

    createProject(api, projectName, folder_id){
        return api.send.post('projects',{name:  projectName, folder_id: folder_id});
    },

    getData(api, project_id){
      return api.send.get(`projects/${project_id}/data`);
    },
    uploadData(api, project_id, files){
        var formData = new FormData();
        formData.append("datafile", files[0]);
        return api.send.post(`projects/${project_id}/load`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    downloadFileForRowNumber(api, project_id, row_number){
        window.open(api.send.fullPath(`projects/${project_id}/download/${row_number}`));
    },
    downloadAllFiles(api, project_id, filenameKey){
        window.open(api.send.fullPath(`projects/${project_id}/download?filenameKey=${filenameKey}`));
    },
    getTemplate(api, project_id){
        return api.send.get(`projects/${project_id}/template`);
    },
    uploadTemplateImage(api, project_id, files){
        var formData = new FormData();
        formData.append("file", files[0]);
        return api.send.post(`projects/${project_id}/template/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    copyTemplateImageFromLibrary(api, project_id, bg_id){
        return api.send.post(`projects/${project_id}/template/copy_from_library`, {bg_id});
    },
    createProjectTemplate(api, project_id, data){
        return api.send.post(`projects/${project_id}/template`,data);
    },

    updateProjectTemplate(api, project_id, data){
        return api.send.put(`projects/${project_id}/template`,data);
    },

    availableFonts(api){
        return api.send.get('fonts');
    },
    listBgTemplates(api){
        return api.send.get('bg_templates');
    },
    loadAccessSheet(api, project_id){
        window.open(api.send.fullPath(`projects/${project_id}/access_sheet`));
    }

}
