<template>
    <div style="padding: 100px;">
        <h1>Добро пожаловать в Грамотакафе - сервис для создания дипломов и грамот.</h1>
        <p>Посмотрите обучающее видео, чтобы познакомиться с основными приемами работы.</p>
        <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/HWnsI7vZoAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <p>Если вам нужна помощь, напишите нам в <a href="https://t.me/gramotacafe" target="_blank">телеграмм</a></p>
    </div>
</template>

<script>
import api from "@/libs/api";
export default {
    mounted() {
        api.getUser().then(data => console.log(data));
    }
}
</script>

<style scoped>

</style>
