<template>
    <div class="flex">
        <div class="w-full "  style="height: calc(100vh - 185px); overflow-y: scroll">
            <TemplatePreview
                v-if="project_id > 0"
                v-model="template"
                :project="props.project"
                @blockClick="activateBlock"
                :activeBlock="activeBlockId"
                @moveBlock="onMoveBlock"
                @onTextWidgetDblClick="enableEditMode"
                :editMode="editMode"
            >

            </TemplatePreview>

        </div>
        <div style="width: 400px; flex-shrink: 0; overflow-y: auto; padding: 0 10px">
            <div class="toolbar">
                <PrimeToolbar>
                    <template #start>
                        <PrimeButton label="+текст" icon="pi pi-plus" @click="addBlock" class="mr-2 p-button-sm" />
                        <FileUpload mode="basic" :custom-upload="true" auto @uploader="addImageBlock" name="datafile" choose-label="+картинка" icon="pi pi-upload" class="p-button-success p-button-sm" />
                        <i class="pi pi-bars p-toolbar-separator mr-2" />
                        <FileUpload mode="basic" :custom-upload="true" auto @uploader="uploadBackgroundImage" name="datafile" choose-label="фон" icon="pi pi-upload" class="p-button-warning p-button-sm" />



                    </template>
                </PrimeToolbar>
            </div>
            <div id="text-blocks">
                <component
                    :is="componentForType(block.type)"
                    v-for="(block,idx) in template.blocks" :key="idx"

                    @deleteBlock="deleteBlock"
                    @clickBlock="activateBlock"
                    v-model="template.blocks[idx]"
                    :active="activeBlockId===block.id"
                    :fonts="fonts"
                    :number="idx+1"
                    />
            </div>

        </div>

    </div>
    <!--div>{{templateStr}}</div-->
</template>

<script setup>
import api from "@/libs/api";
import TextBlock from "@/components/TextBlockNew";
import ImageBlock from "@/components/ImageBlock";
import TemplatePreview from "@/components/TemplatePreview";

import {ref, computed, defineComponent, defineProps,  onUnmounted} from "vue"
import {useRoute} from "vue-router"

import {round,  pixelToMM } from "@/libs/utils"


defineComponent(TextBlock);
defineComponent(ImageBlock);
defineComponent(TemplatePreview);


const props = defineProps(['project']);

const project_id = ref(-1);
const activeBlockId = ref(-1);

const componentForType = (blockType) => {
    switch(blockType) {
        case 'text':
            return TextBlock;

        case 'img':
            return ImageBlock;
    }
};


const fonts = ref([]);


const template = ref({blocks:[]});


const activeBlock = computed(() => activeBlockId.value !== -1 ? template.value.blocks.filter(block => block.id === activeBlockId.value)[0] : null)

const onMoveBlock = (event) =>{
    let block = template.value.blocks.filter(block => block.id === event.blockId)[0];
    block.x += event.x;
    block.y += event.y;
}

const deleteBlock = (blockId) => template.value.blocks = template.value.blocks.filter(block => block.id !== blockId);

const itemToSave = computed(() => {
    let result = Object.assign({},template.value);
    result.blocks = JSON.stringify(result.blocks.map( block => {
        block.border = block.border_width > 0 ? 1 :0
        return block;
    }));
    return result;
})

const save = () => api.updateProjectTemplate(project_id.value, itemToSave.value);
onUnmounted(() => save() );


const  makeId = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const addBlock = () =>{
    template.value.blocks.push({
        id: makeId(8),
        x:0,
        y:0,
        width: 200,
        height: 10,
        border: 0,
        border_width: 0,
        border_color: '#000000',
        background: "",
        text: 'введите текст',
        type: 'text',
        align: 'C',
        font:{
            color: '#000',
            size: 16,
            style: '',
            family: 'Arial'
        }
    });
}

const load = () => {
    api.viewProjectTemplate(project_id.value)
        .then( data => {

            data.blocks = JSON.parse(data.blocks).map(block => {
                if(block.background === undefined) block.background = "";
                if(block.border_width === undefined) {
                    block.border_width = 0;
                } else {
                    block.border_width = parseInt(block.border_width);
                }
                if(block.border_color === undefined) block.border_color = "#000000";
                return block;
            });
            template.value = data
        })
}
const uploadBackgroundImage = (data) =>{
    api.uploadProjectTemplateImage(project_id.value, data.files).then( (data) => template.value.background_file = data.filename);
}

const addImageBlock = (data) => {
    api.uploadProjectTemplateImage(project_id.value, data.files).then(
        (data) => {
            let imgBlock = {
                id: makeId(8),
                x:0,
                y:0,
                width: 200,
                height: 10,
                border: 0,
                border_width: 0,
                border_color: '#000000',
                type: 'img',
                src: data.filename,
                ext: data.ext,
                orig_width: pixelToMM(data.width),
                orig_height: pixelToMM(data.height),
                keepProportional: 1,
                rate: round(data.width/data.height, 2)
            }

            let maxWidth  = template.value.width;
            let maxheight  = template.value.height;

            if (imgBlock.orig_width > imgBlock.orig_height){
                imgBlock.width = imgBlock.orig_width > maxWidth ? maxWidth : imgBlock.orig_width;
                imgBlock.height = imgBlock.width / imgBlock.rate;
            } else {
                imgBlock.height = imgBlock.orig_height > maxheight ? maxheight : imgBlock.orig_height;
                imgBlock.width = imgBlock.rate * imgBlock.height;
            }
            template.value.blocks.push(imgBlock)

        });
}




const moveUp = (pixels) => {
    if (activeBlock.value.y - pixels > 0) activeBlock.value.y -= pixels;
    else activeBlock.value.y = 0
}

const moveDown = (pixels) =>  {
    if (activeBlock.value.y + activeBlock.value.height + pixels < template.value.height) activeBlock.value.y += pixels;
    else activeBlock.value.y = template.value.height - activeBlock.value.height ;
}

const moveLeft = (pixels) => {
    if (activeBlock.value.x - pixels > 0) activeBlock.value.x -= pixels;
    else activeBlock.value.x = 0
}

const moveRight = (pixels) => {
    if (activeBlock.value.x + activeBlock.value.width + pixels < template.value.width)
        activeBlock.value.x += pixels;
    else activeBlock.value.x= template.value.width - activeBlock.value.width;
}

const increaseWidth = (pixels) => activeBlock.value.width += pixels;
const decreaseWidth = (pixels) => activeBlock.value.width - pixels > 30 ? activeBlock.value.width -= pixels : 0;

const increaseHeight = (pixels) =>{
    const pixelsInMm = 3.79;
    let fontSizeInMM = activeBlock.value.font.size / pixelsInMm;
    if (activeBlock.value.height - pixels > fontSizeInMM) activeBlock.value.height -= pixels;
    else activeBlock.value.height = fontSizeInMM;
}
const decreaseHeight = (pixels) => activeBlock.value.height += pixels;

api.getAvailableFonts().then(data => fonts.value = data);

const editMode = ref(false);
const enableEditMode = () => {editMode.value = true};
const disableEditMode = () => {editMode.value = false};
const activateBlock = (blockId) => {
    activeBlockId.value = blockId;
    disableEditMode();
}
document.addEventListener('keydown',(event) => {
    if(activeBlock.value !== null && !editMode.value){
        const isArrowKey = ['ArrowRight', 'ArrowDown', 'ArrowUp', 'ArrowLeft'].includes(event.code);
        if(!isArrowKey) return;

        event.preventDefault();
        event.stopPropagation();

        let moveHandler = {
            ArrowUp: moveUp,
            ArrowDown: moveDown,
            ArrowLeft: moveLeft,
            ArrowRight: moveRight,
        }
        let resizeHandler = {
            ArrowUp: increaseHeight,
            ArrowDown: decreaseHeight,
            ArrowLeft: decreaseWidth,
            ArrowRight: increaseWidth,
        }
        let step = event.shiftKey ? 10 : 1;
        let handler = event.ctrlKey ? resizeHandler : moveHandler;
        handler[event.code](step);





    }
});
project_id.value = useRoute().params.project_id;
load();



</script>

<style scoped>

</style>
